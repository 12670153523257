import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './styles/custom_style.scss';
import Auth from './mixins/auth';
import Helper from './mixins/helper';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;
Vue.prototype.$Auth = Auth;
Vue.prototype.$Helper = Helper;

// make app initial set up
store.dispatch('initialSetUp').then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}).catch(error => {
  console.log('main.js initialSetUp error: ' + error);
});