export default {
    methods: {
        // generate crypto key that will be used to encrypt/decrypt data locally
        async generateKey() {
            return window.crypto.subtle.generateKey({name: "AES-CBC", length: 128}, false, ["encrypt", "decrypt"]);
        },
        // encrypt data given key and iv
        async encrypt(data, key, iv) {
            const encoder = new TextEncoder();
            return window.crypto.subtle.encrypt({name: "AES-CBC", iv: iv}, key, encoder.encode(JSON.stringify(data)));
        },
        // decrypt data given key and iv
        async decrypt(data, key, iv) {
            const decoder = new TextDecoder();
            return JSON.parse(decoder.decode(await window.crypto.subtle.decrypt({name: "AES-CBC", iv: iv}, key, data)));
        },
        // create hash given the sha algorithm and the string
        async digest(algorithm, str) {
            const buf = await window.crypto.subtle.digest({name: algorithm}, new TextEncoder("utf-8").encode(str));
            return Array.prototype.map.call(new Uint16Array(buf), x => (('00' + x.toString(16)).slice(-2))).join('');
        }
    }
}