<template>
  <v-app>

    <!--&lt;!&ndash;sidebar&ndash;&gt;-->
    <!--<Sidebar v-if="isAuthenticated" :drawer="drawer" :mini_menu="mini_menu"-->
             <!--@drawerChanged="drawerChanged" @miniMenuChanged="miniMenuChanged"></Sidebar>-->

    <!--&lt;!&ndash;header&ndash;&gt;-->
    <!--<Header :isAuthenticated="isAuthenticated" :drawer="drawer" :mini_menu="mini_menu"-->
            <!--@drawerChanged="drawerChanged" @miniMenuChanged="miniMenuChanged"></Header>-->

    <v-main>
      <ResponseNotification></ResponseNotification>
      <router-view></router-view>
    </v-main>

    <Footer></Footer>

  </v-app>
</template>

<script>
//    import Header from './components/Header.vue'
//    import Sidebar from './components/Sidebar.vue'
    import Footer from './components/Footer.vue'
    import ResponseNotification from './components/ResponseNotification.vue'

    export default {
        name: 'App',
        components: {
//            Header,
//            Sidebar,
            Footer,
            ResponseNotification
        },
        data: () => ({
            drawer: true,
            mini_menu: true
        }),
        computed: {
            isAuthenticated() {
                return this.$store.getters.isLoggedIn && this.$store.getters.showContent;
            }
        },

        created() {
//      window.addEventListener('beforeunload', this.leaving)
        },
        watch: {
//            '$route'(to, from) {
//                if (['incidents'].indexOf(to.name) > -1) {
//                    setTimeout(() => {
//                        this.mini_menu = true;
//                    }, 50);
//                }
//            }
        },
        methods: {
            // change on drawer
            drawerChanged(val) {
                this.drawer = val;
            },
            // change on menu mini
            miniMenuChanged(val) {
                this.mini_menu = val;
            }
        }
    };
</script>
