<template>
  <v-snackbar class="response_notification" v-model="$store.state.show_response" top
              :timeout="$store.state.response_timeout" :color="$store.state.response_type">
    <v-row dense>
      <v-col cols="1" align-self="center">
        <v-btn text icon id="notify_btn">
          <v-icon>notification_important</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="10">
        <ul class="response_list">
          <li v-for="(data, index) in $store.state.response" :key="index">{{data}}</li>
        </ul>
      </v-col>
      <v-col cols="1" align-self="center">
        <v-btn text icon id="close_btn" @click="closeResponseNotification">
          <v-icon>close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
    export default {
        methods: {
            // close response notification
            closeResponseNotification() {
                this.$store.commit('mutateShowResponse', false);
            }
        }
    }
</script>